.verification_done {
  text-align: left;
}

.verification_done > h1 {
  margin-bottom: 7px;
}

.desc {
  margin-bottom: 20px;
}

.redirectLink {
  text-decoration: underline;
  color: #3ebee4;
}

.counter {
  color: red;
}
